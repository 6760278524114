import React from "react";
import { API_URL } from "../../Utill/Config";
import img2 from '../../Images/Icons/2.png'
import img3 from '../../Images/Icons/3.png'
import img4 from '../../Images/Icons/4.png'
import img5 from '../../Images/Icons/5.png'
import img6 from '../../Images/Icons/6.png'
import img7 from '../../Images/Icons/7.png'

function LandingPageScreen() {

    function goLineOA() {
        window.location.replace(API_URL.LINEOA_CHANNEL);
    }

    return (
        <>
            <div style={{ backgroundColor: `rgb(123 123 123)`, position: `absolute`, width: `100%` }}>
                <div style={{ padding: 10 }}>
                    <div style={{ marginTop: 20, backgroundColor: `white`, padding: 15, borderRadius: 5, display: `flex`, justifyContent: `center`, alignItems: `center`, flexDirection: `column`, boxShadow: `rgb(175 175 175) 2px 2px 10px 0px`, textAlign: `center` }}>
                        <div style={{ fontSize: 24, fontWeight: `bold` }}>
                            Topic Creator
                        </div>
                        <div style={{ fontSize: 14, }}>
                            ผู้ช่วยสร้างคอนเทนต์ให้กับคุณในคลิกเดียว
                        </div>
                        <div style={{ fontSize: 14, marginTop: 15, color: `#479e4d`, fontWeight: `bold` }}>
                            หากตอนนี้คุณยังไม่รู้จะเริ่มต้นทำคอนเทนต์อะไร?
                        </div>
                        <div style={{ fontSize: 14, color: `#479e4d`, fontWeight: `bold` }}>
                            Topic creator จะเป็นผู้ช่วยคิดคอนเทนต์ปังๆ ให้กับคุณ
                        </div>

                        <div style={{ textAlign: `left` }}>
                            <div style={{ fontSize: 16, fontWeight: `bold`, marginTop: 15 }}>
                                สิ่งที่จะได้รับจาก Topic creator
                            </div>
                            <li style={{ fontSize: 14, marginTop: 10 }}>ไม่จำเป็นต้องเก่งเทคโนโลยี</li>
                            <li style={{ fontSize: 14 }}>ไม่จำเป็นต้องใช้ AI เป็น</li>
                            <li style={{ fontSize: 14 }}>ไม่จำเป็นต้องเรียนรู้ใหม่</li>
                            <li style={{ fontSize: 14 }}>ไม่จำเป็นต้องเข้าใจอะไรที่ซับซ้อน</li>
                        </div>

                        <div style={{ fontSize: 14, marginTop: 15, }}>
                            เพราะ topic creator ใช้งานง่ายผ่านไลน์
                        </div>
                        <div style={{ fontSize: 14, }}>
                            เหมือนพูดคุยกับเพื่อนของตัวเอง
                        </div>

                        <div style={{ fontSize: 14, marginTop: 15, fontWeight: `bold` }}>
                            ไม่ต้องกังวลว่าจะคิดคอนเทนต์ไม่ออก
                        </div>
                        <div style={{ fontSize: 14, fontWeight: `bold` }}>
                            ไม่ต้องกังวลว่าคอนเทนต์จะไม่โดนใจกลุ่มเป้าหมาย
                        </div>
                    </div>

                    <div style={{ marginTop: 20, backgroundColor: `white`, padding: 15, borderRadius: 5, display: `flex`, justifyContent: `center`, alignItems: `center`, flexDirection: `column`, boxShadow: `rgb(175 175 175) 2px 2px 10px 0px`, textAlign: `center` }}>
                        <div style={{ fontSize: 24, fontWeight: `bold` }}>
                            Topic Creator
                        </div>

                        <div style={{ textAlign: `left` }}>
                            <li style={{ fontSize: 14, marginTop: 10 }}>ช่วยคุณคิดคอนเทนต์ได้ตรงใจกลุ่มเป้าหมาย</li>
                            <li style={{ fontSize: 14 }}>ช่วยคุณวิเคราะห์กลุ่มเป้าหมายได้ละเอียดเหมือนมีที่ปรึกษาส่วนตัว</li>
                            <li style={{ fontSize: 14 }}>สร้างไอเดียให้คุณ ไม่ซ้ำ ตรงประเด็น และดึงดูดกลุ่มเป้าหมายที่ใช่</li>
                            <li style={{ fontSize: 14 }}>ช่วยคุณเขียนคอนเทนต์ได้รวดเร็ว ประหยัดเวลา และแนะนำหัวข้อที่น่าสนใจ</li>
                            <li style={{ fontSize: 14 }}>ช่วยคุณกระตุ้นความคิด เปิดมุมมองใหม่ๆ ต่อยอดได้ไม่รู้จบ</li>
                        </div>
                    </div>


                    <div style={{ marginTop: 15, backgroundColor: `white`, padding: `10px 10px 20px 10px`, borderRadius: 5, display: `flex`, justifyContent: `center`, alignItems: `center`, flexDirection: `column`, boxShadow: `rgb(175 175 175) 2px 2px 10px 0px`, textAlign: `center` }}>
                        <div style={{ display: `flex`, flexDirection: `row`, alignItems: `center`, justifyContent: `space-around`, width: `100%`, marginTop: 20 }}>
                            <div style={{ display: `flex`, flexDirection: `column`, justifyContent: `center`, alignItems: `center` }}>
                                <img src={img2} style={{ width: 70 }} alt="" />
                                <div style={{ fontSize: 12, marginTop: 10 }}>คิดหัวข้อคอนเทนต์</div>
                                <div style={{ fontSize: 12 }}>ที่ตรงใจกลุ่มเป้าหมาย</div>
                            </div>
                            <div style={{ display: `flex`, flexDirection: `column`, justifyContent: `center`, alignItems: `center` }}>
                                <img src={img3} style={{ width: 70 }} alt="" />
                                <div style={{ fontSize: 12, marginTop: 10 }}>วิเคราะห์เทรนด์</div>
                                <div style={{ fontSize: 12 }}>รู้ทันกระแส</div>
                            </div>
                            <div style={{ display: `flex`, flexDirection: `column`, justifyContent: `center`, alignItems: `center`, paddingBottom: 17 }}>
                                <img src={img4} style={{ width: 70 }} alt="" />
                                <div style={{ fontSize: 12, marginTop: 10 }}>สร้างไอเดีย ไม่ซ้ำใคร</div>
                                <div style={{ fontSize: 12 }}></div>
                            </div>
                        </div>

                        <div style={{ display: `flex`, flexDirection: `row`, alignItems: `center`, justifyContent: `space-around`, width: `100%`, marginTop: 20 }}>
                            <div style={{ display: `flex`, flexDirection: `column`, justifyContent: `center`, alignItems: `center` }}>
                                <img src={img5} style={{ width: 70 }} alt="" />
                                <div style={{ fontSize: 12, marginTop: 10 }}>เขียนคอนเทนต์ รวดเร็ว</div>
                                <div style={{ fontSize: 12 }}>ประหยัดเวลา</div>
                            </div>
                            <div style={{ display: `flex`, flexDirection: `column`, justifyContent: `center`, alignItems: `center` }}>
                                <img src={img6} style={{ width: 70 }} alt="" />
                                <div style={{ fontSize: 12, marginTop: 10 }}>เพิ่มยอด Engagement</div>
                                <div style={{ fontSize: 12 }}>ให้กับโพสต์ของคุณ</div>
                            </div>
                            <div style={{ display: `flex`, flexDirection: `column`, justifyContent: `center`, alignItems: `center`, }}>
                                <img src={img7} style={{ width: 70 }} alt="" />
                                <div style={{ fontSize: 12, marginTop: 10 }}>กระตุ้นให้คนอ่าน</div>
                                <div style={{ fontSize: 12 }}>กดไลค์ แชร์ คอมเมนต์</div>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: 15, backgroundColor: `white`, padding: `20px 10px`, borderRadius: 5, display: `flex`, justifyContent: `center`, alignItems: `center`, flexDirection: `column`, boxShadow: `rgb(175 175 175) 2px 2px 10px 0px`, textAlign: `center` }}>
                        <div style={{ display: `flex`, alignItems: `center`, justifyContent: `space-around`, width: `100%`, flexDirection: `column` }}>
                            <div style={{ fontSize: 14 }}>
                                Topic creator ไม่เพียงแค่สร้างคอนเทนต์ดีๆ
                            </div>
                            <div style={{ fontSize: 14, }}>
                                แต่ยังช่วยให้คุณมีคอนเทนต์ลงได้เป็นปี
                            </div>
                            <div style={{ fontSize: 14, }}>
                                กับเนื้อหาที่จะทำให้คนดูของคุณต้องรีบกดไลค์ กดแชร์
                            </div>
                            <div style={{ fontSize: 14, }}>
                                และกระหน่ำคอมเมนต์
                            </div>

                            <div style={{ fontSize: 14, marginTop: 15, fontWeight: `bold` }}>
                                มาถึงตอนนี้คุณพร้อมจะมีที่ปรึกษาคอนเทนต์ดีๆ รึยัง!!!
                            </div>

                            <div onClick={() => { goLineOA() }} style={{ backgroundColor: `#479e4d`, width: 170, padding: 5, borderRadius: 50, textAlign: `center`, fontWeight: `bold`, fontSize: 18, color: `white`, marginTop: 20, boxShadow: `2px 2px 5px 0px #9b9b9b` }}>
                                ไปที่ LineOA
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default LandingPageScreen;
