
import React from 'react';

import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import MainScreen from './components/Main/MainScreen';
import AffiliateScreen from './components/Affiliate/AffiliateScreen';
import LandingPageScreen from './components/LandingPage/LandingPageScreen';
import SplashScreen from './components/SplashScreen/SplashScreen';
import VConsole from 'vconsole';
import { API_URL } from './Utill/Config';

function App() {
  if (API_URL.ENV === `DEV`) {
    new VConsole()
  }
  return (
    <Router basename={`/`}>
      <Routes>
        <Route exact path="/" element={<MainScreen />} />
        <Route exact path="/affiliate" element={<AffiliateScreen />} />
        <Route exact path="/register" element={<LandingPageScreen />} />

        <Route exact path="/splashscreen" element={<SplashScreen />} />
        <Route path="*" element={<Navigate replace to="/" />} />

      </Routes>
      {/* <BottomBar /> */}
    </Router>
  );
}

export default App;
